import * as styles from './ItemWithMenu.module.scss'
import cn from 'classnames'
import React, { useState } from 'react'
import { useDelayUnmount } from 'utils'

import Content from './Content'
import { Title } from 'ui'
import ArrowIcon from 'components/Icons/Arrow'

const ItemWithMenu = ({ title, description }) => {
  const [isOpenMenu, setIsOpenMenu] = useState(false)
  const shouldRenderChild = useDelayUnmount(isOpenMenu, 350)

  const handleToggle = () => setIsOpenMenu(prev => !prev)

  return (
    <div className={styles.wrapper}>
      <div className={styles.wrapper__container} onClick={handleToggle}>
        <Title text={title} variant="small" />
        <ArrowIcon
          className={cn(styles.wrapper__container__icon, {
            [styles.toggled]: isOpenMenu,
          })}
        />
      </div>
      {shouldRenderChild && (
        <div className="row">
          <Content text={description} open={isOpenMenu} />
        </div>
      )}
    </div>
  )
}

export default ItemWithMenu
