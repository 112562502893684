import * as styles from './ServicePage.module.scss'
import * as React from 'react'

import { graphql } from 'gatsby'
import Layout from 'components/layout'
import SEO from 'components/seo'

import { LogosTicker, Title, SubTitle } from 'ui'

import ContactButtonModal from './ContactButtonModal'
import Processes from './Processes'
import QuestionsSection from './QuestionsSection'

import BreadCrumb from 'components/BreadCrumb'
import ProudOfSection from 'components/ProudOfSection'
import SectionWithScrollBg from 'components/SectionWithScrollBg'
import TextWithCubes from 'components/TextWithCubes'
import FixableCards from 'components/FixableCards'
import ClientsSwiper from 'components/ClientsSwiper'
import BlogItemsList from 'components/BlogItemsList'
import Contact from 'components/Contact'

import services_settings from 'site/services/services_settings.json'
import services_benefits from 'site/services/services_benefits.json'
import contact_settings from 'site/settings/contact_settings.json'
import siteConfig from 'site/settings/site_config.json'

const ServicePage = ({ location, data }) => {
  const frontmatter = data.markdownRemark.frontmatter

  const portfolio_items_to_show = frontmatter.portfolio_items_to_show || []
  const blog_items_to_show = frontmatter.blog_items_to_show || []

  return (
    <Layout
      ExtraTopComponent={
        <BreadCrumb
          linkTo="/services"
          linkTitle="Services"
          title={frontmatter.title}
        />
      }
      ExtraRightComponent={<ContactButtonModal />}
      title={`${frontmatter.title} Services`}
      subTitle={frontmatter.description}>
      <SEO
        title={frontmatter.meta_title || frontmatter.title}
        description={frontmatter.meta_description || frontmatter.description}
        location={location?.href}
      />
      <LogosTicker />
      <Processes
        title={frontmatter.processes_title}
        subTitle={frontmatter.processes_description}
        processesList={frontmatter.processes_list}
      />
      <ProudOfSection
        itemsToShow={2}
        filterCallback={({ fields: { slug } }) =>
          portfolio_items_to_show.includes(slug.replace(/\//g, ''))
        }
        className="servicesProudof"
        subTitleClassName={styles.proudOfSubTitle}
      />
      <SectionWithScrollBg className="servicesWeRockSingle">
        <div className="row">
          <div className="col-xxl-8 col-lg-8 col-md-12">
            <Title text={services_benefits.title_single_main} />
            <SubTitle text={services_benefits.subtitle_single_main} />
          </div>
        </div>
        <TextWithCubes
          title_1={services_benefits.title_1}
          sub_title_1={services_benefits.sub_title_1}
          title_2={services_benefits.title_2}
          sub_title_2={services_benefits.sub_title_2}
          title_3={services_benefits.title_3}
          sub_title_3={services_benefits.sub_title_3}
        />
        <div className="row">
          <div className="col-xxl-8 col-lg-8 col-md-12">
            <Title text={services_settings.services_cards_title} />
            {/* <SubTitle text={'benefits_cards_settings.sub_title'} /> */}
          </div>
        </div>
        <FixableCards mapItems={services_settings.services_cards} />
      </SectionWithScrollBg>
      <SectionWithScrollBg className="servicesReviewsSingle">
        <ClientsSwiper
          progressClass={styles.swiperProgress}
          title={services_settings.slider_single_title}
          subTitle={services_settings.slider_single_sub_title}
        />
        <BlogItemsList
          itemsToShow={2}
          filterCallback={({ fields: { slug } }) =>
            blog_items_to_show.includes(slug.replace(/\//g, ''))
          }
          title={services_settings.blog_title}
          subTitle={services_settings.blog_sub_title}
          linkText={services_settings.blog_link_text}
        />
      </SectionWithScrollBg>
      {frontmatter.questions_list?.length > 0 && (
        <QuestionsSection
          title={frontmatter.questions_title}
          mapItems={frontmatter.questions_list}
        />
      )}
      <Contact
        className={frontmatter.questions_list?.length > 0 ? '' : styles.contact}
        title={contact_settings.title}
        subTitle={
          frontmatter.contact_description ||
          siteConfig.services_single_contact_description
        }
      />
    </Layout>
  )
}

export default ServicePage

export const pageQuery = graphql`
  query ServicesItemQuery($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        description
        image {
          publicURL
        }
        servicespage_description
        processes_title
        processes_description
        processes_list {
          title
          description
          icon {
            name
          }
          rotation
        }
        questions_title
        questions_list {
          title
          description
        }
        portfolio_items_to_show
        blog_items_to_show
        meta_title
        meta_description
        contact_description
      }
    }
  }
`
