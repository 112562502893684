// extracted by mini-css-extract-plugin
export var card = "Card-module--card--uSv59";
export var card__header = "Card-module--card__header--LLbnf";
export var card__header__icon = "Card-module--card__header__icon--hSiVy";
export var card__header__par = "Card-module--card__header__par--Zk4Nv";
export var fixed = "Card-module--fixed--Ab83r";
export var move = "Card-module--move--ZpRz8";
export var shake = "Card-module--shake--HDXSC";
export var shaking = "Card-module--shaking--C0cVl";
export var visible = "Card-module--visible--WbpYF";
export var wrapper = "Card-module--wrapper--8GAy-";
export var wrapper__buttonWrap = "Card-module--wrapper__buttonWrap--YjEYI";
export var wrapper_fixed = "Card-module--wrapper_fixed--2--OU";