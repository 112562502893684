import * as styles from './QuestionsSection.module.scss'
import React from 'react'

import ItemWithMenu from './ItemWithMenu'
import SectionWithScrollBg from 'components/SectionWithScrollBg'
import { Title, SubTitle } from 'ui'

const QuestionsSection = ({ title, subTitle, mapItems }) => {
  return (
    <SectionWithScrollBg className="servicesQuestions">
      <div className="container">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={title} />
          {subTitle && <SubTitle text={subTitle} />}
        </div>
        <div className={styles.wrapper}>
          {mapItems.map((props, index) => (
            <ItemWithMenu
              {...props}
              key={`QuestionsSection-ItemWithMenu-${index}`}
            />
          ))}
        </div>
      </div>
    </SectionWithScrollBg>
  )
}

export default QuestionsSection
