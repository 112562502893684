import React, { useState } from 'react'
import * as styles from './Card.module.scss'
import cn from 'classnames'

import { Title, SubTitle, ActionButton } from 'ui'
import { useDelayUnmount } from 'utils'

export const Card = ({
  Icon,
  title,
  description,
  shouldRenderPar = false,
  isParVisible = false,
  onClick = () => {},
  as,
  className,
  ...props
}) => {
  const Component = as || 'div'
  return (
    <Component
      className={cn(styles.card, className)}
      onClick={onClick}
      {...props}>
      <div className={styles.card__header}>
        {Icon && <Icon className={styles.card__header__icon} />}
        {shouldRenderPar && (
          <p
            className={cn(styles.card__header__par, {
              [styles.visible]: isParVisible,
            })}>
            Fixed!
          </p>
        )}
      </div>
      <Title variant="small" text={title} />
      <SubTitle variant="small" text={description} />
    </Component>
  )
}

export const CardWithAnimation = ({ Icon, title, description }) => {
  const [isBreaked, setIsBreaked] = useState(true)
  const [hovered, setHovered] = useState(false)
  const shouldRenderButton = useDelayUnmount(hovered, 150)
  const shouldNotRenderPar = useDelayUnmount(isBreaked, 450)

  const handleMouseEnter = () => setHovered(true)
  const handleMouseLeave = () => setHovered(false)

  const handleClick = () => setIsBreaked(false)

  return (
    <div
      className={cn(styles.wrapper, {
        [styles.wrapper_fixed]: !isBreaked,
      })}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}>
      <Card
        as="button"
        type="button"
        onClick={handleMouseEnter}
        shouldRenderPar={!shouldNotRenderPar}
        isParVisible={!isBreaked}
        Icon={Icon}
        title={title}
        description={description}
        className={isBreaked ? styles.shaking : styles.fixed}
      />
      {isBreaked && shouldRenderButton && (
        <div className={styles.wrapper__buttonWrap}>
          <ActionButton
            title="Fix it!"
            onClick={handleClick}
            isMounted={hovered}
          />
        </div>
      )}
    </div>
  )
}
