import * as styles from './Content.module.scss'
import React, { useEffect, useRef, useState } from 'react'
import cn from 'classnames'

import { SubTitle } from 'ui'

const Content = ({ text, open }) => {
  const [isHidden, setisHidden] = useState(true)
  const itemRef = useRef(null)

  useEffect(() => {
    if (itemRef.current?.offsetHeight > 0) {
      itemRef.current.classList.remove(styles.none)

      itemRef.current.style.setProperty(
        '--height',
        `${itemRef.current.offsetHeight}px`,
      )
      setisHidden(false)
    }
  }, [])

  return (
    <div
      className={cn(
        'col-xxl-8 col-xl-8 col-md-10 col-sm-12',
        styles.container,
        isHidden ? styles.none : open ? styles.fadeIn : styles.fadeOut,
      )}
      ref={itemRef}>
      <SubTitle text={text} />
    </div>
  )
}

export default Content
