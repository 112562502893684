import React from 'react'
const Arrow = props => (
  <svg
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M18.5 8.5L11.5 15.5L4.5 8.5"
      strokeWidth="2"
      strokeLinecap="square"
    />
  </svg>
)

export default Arrow
