import React from 'react'
import * as styles from './FixableCards.module.scss'

import { CardWithAnimation, Card } from './Card'

const FixableCards = ({ mapItems }) => {
  return (
    <div className={styles.cards}>
      {mapItems.map(({ description, icon, is_need_fix, title }, i) =>
        is_need_fix ? (
          <CardWithAnimation
            Icon={require(`content/assets${mapItems[i].icon.slice(9)}`).default}
            title={title}
            description={description}
            key={`benefit_card-${i}`}
          />
        ) : (
          <Card
            Icon={require(`content/assets${mapItems[i].icon.slice(9)}`).default}
            title={title}
            description={description}
            key={`benefit_card-${i}`}
          />
        ),
      )}
    </div>
  )
}
export default FixableCards
