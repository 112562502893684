import * as styles from './Processes.module.scss'
import React, { useState, useRef, useEffect } from 'react'

import { Title, SubTitle } from 'ui'
import Square from 'ui/Square'
import SectionWithScrollBg from 'components/SectionWithScrollBg'
import Item from './Item'

import { isMobile } from 'react-device-detect'
const getIcon = name => {
  try {
    return require(`content/assets/${name}.svg`).default
  } catch (error) {
    console.log('Icon not found')
    console.log(error)
    return null
  }
}
const ProcessesWeb = ({ title, subTitle, processesList }) => {
  const squareWrapRef = useRef(null)
  const squareRef = useRef(null)

  const [isHidden, setIsHidden] = useState(false)
  const [currentIconSrc, setCurrentIconSrc] = useState('')
  const [currentRotation, setCurrentRotation] = useState(0)
  const [trailingX, setTrailingX] = useState(false)
  const [trailingY, setTrailingY] = useState(false)

  const onSetCurrentIconSrc = src => setCurrentIconSrc(src)
  const onSetCurrentRotation = rotation => setCurrentRotation(rotation)

  const resetTrailing = () => {
    setTrailingX(false)
    setTrailingY(false)
  }
  const onHide = () => {
    setIsHidden(true)
    resetTrailing()
  }
  const onShow = () => setIsHidden(false)

  const onSetTrailingX = pos => {
    setTimeout(() => {
      setTrailingX(pos)
    }, 100)
  }
  const onSetTrailingY = pos => {
    setTimeout(() => {
      setTrailingY(pos)
    }, 100)
  }

  const isHide = !trailingX && !trailingY && isHidden

  useEffect(() => {
    if (!squareWrapRef.current) return
    if (isHidden) {
      resetTrailing()
      return
    }
    squareWrapRef.current.style.setProperty('--x', `${trailingX}px`)
    squareWrapRef.current.style.setProperty('--y', `${trailingY}px`)
  }, [trailingX, trailingY, isHidden])

  useEffect(() => {
    if (!squareRef.current) return
    squareRef.current.style.setProperty('--rotation', `${currentRotation}deg`)
  }, [currentRotation])

  return (
    <SectionWithScrollBg className="serviceProcesses">
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={title} />
          {subTitle && <SubTitle text={subTitle} />}
        </div>
      </div>
      <div
        className={styles.container}
        onMouseLeave={onHide}
        onMouseEnter={onShow}>
        {processesList.map((props, index) => (
          <Item
            {...props}
            step={index + 1}
            onSetTrailingX={onSetTrailingX}
            onSetTrailingY={onSetTrailingY}
            onSetCurrentIconSrc={onSetCurrentIconSrc}
            onSetCurrentRotation={onSetCurrentRotation}
            key={`Processes-item-${index}`}
          />
        ))}
      </div>
      <div
        className={isHide ? styles.none : styles.squareWrap}
        ref={squareWrapRef}>
        <Square Icon={getIcon(currentIconSrc)} ref={squareRef} />
      </div>
    </SectionWithScrollBg>
  )
}

const ProcessesMob = ({ title, subTitle, processesList }) => {
  return (
    <SectionWithScrollBg className="serviceProcesses">
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={title} />
          {subTitle && <SubTitle text={subTitle} />}
        </div>
      </div>
      <div className={styles.container}>
        {processesList.map((props, index) => (
          <Item {...props} step={index + 1} key={`Processes-item-${index}`} />
        ))}
      </div>
    </SectionWithScrollBg>
  )
}

const Processes = props =>
  isMobile ? <ProcessesMob {...props} /> : <ProcessesWeb {...props} />

export default Processes
