import * as styles from './ContactButtonModal.module.scss'
import React, { useState } from 'react'

import { DefaultButton as Button } from 'ui'

import Modal from 'components/Modal'
import Form from 'components/FrontPage/FeedbackSection/Form/Form'

const ContactButtonModal = () => {
  const [modalIsOpen, setmodalIsOpen] = useState(false)
  const handleOpenModal = () => setmodalIsOpen(true)
  const handleCloseModal = () => setmodalIsOpen(false)
  return (
    <>
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Button
            title={`Let's talk`}
            type="button"
            onClick={handleOpenModal}
            className={styles.button}
          />
        </div>
      </div>
      <Modal isOpen={modalIsOpen} onClose={handleCloseModal}>
        <Form isModal afterSubmit={handleCloseModal} />
      </Modal>
    </>
  )
}

export default ContactButtonModal
