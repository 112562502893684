import React, { useState, useEffect } from 'react'
import * as styles from './BlogItemsList.module.scss'

import { Title, SubTitle, DefaultLink } from 'ui'
import { useStaticQuery, graphql } from 'gatsby'
import Post from './Post'

const BlogItemsList = ({
  title,
  subTitle,
  linkText,
  itemsToShow,
  filterCallback,
}) => {
  const [cropedPosts, setCropedPosts] = useState([])

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(blog)/" } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          nodes {
            excerpt
            fields {
              slug
            }
            frontmatter {
              date(formatString: "MMMM DD")
              title
              description
              image {
                publicURL
              }
            }
          }
        }
      }
    `,
  )

  const posts = allMarkdownRemark.nodes

  useEffect(() => {
    if (!posts) {
      return console.log('no post')
    }
    const filteredPosts = posts
      .filter(filterCallback)
      .filter((item, i) => i < itemsToShow)
    setCropedPosts(filteredPosts)
  }, [posts])

  return (
    <section className={styles.blog}>
      <div className="col-xxl-8 col-lg-8 col-md-12">
        <Title text={title} />
        <SubTitle text={subTitle} />
      </div>
      <div className={styles.posts}>
        <div className="row">
          {cropedPosts?.map((post, i) => (
            <div
              className="col-xxl-6 col-xl-6 col-md-6 col-sm-12"
              // itemScope
              // itemType="http://schema.org/Article"
              key={`blog-item-${post.fields.slug}-${i}`}>
              <Post
                link={`/blog${post.fields.slug}`}
                imageSrc={post.frontmatter.image?.publicURL}
                title={post.frontmatter.title || post.fields.slug}
                category={post.frontmatter.categories}
                author={post.frontmatter.author}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="d-flex justify-content-center">
        <DefaultLink to="/blog" title={linkText} />
      </div>
    </section>
  )
}

export default BlogItemsList
