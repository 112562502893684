import * as styles from './Item.module.scss'
import React from 'react'
import cn from 'classnames'

import { Title, SubTitle } from 'ui'

const Item = ({
  title,
  description,
  icon,
  rotation,
  step,
  onSetTrailingX,
  onSetTrailingY,
  onSetCurrentIconSrc,
  onSetCurrentRotation,
}) => {
  const onMouseMove = ({ clientX, clientY }) => {
    onSetTrailingX(clientX)
    onSetTrailingY(clientY)
  }

  const onMouseEnter = () => {
    onSetCurrentIconSrc(icon.name)
    onSetCurrentRotation(rotation)
  }

  return (
    <div
      className={cn('row', styles.item)}
      onMouseMove={onMouseMove}
      onMouseEnter={onMouseEnter}>
      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-column">
        <p className={styles.step}>Step {step}</p>
        <Title text={title} variant="small" />
      </div>
      <div className="col-xxl-6 col-lg-6 col-md-6 col-sm-12 d-flex flex-column justify-content-center">
        <SubTitle text={description} variant="small" />
      </div>
    </div>
  )
}

export default Item
