import * as styles from './ProudOfSection.module.scss'

import React, { useState, useEffect } from 'react'

import { Title, SubTitle, DefaultLink } from 'ui'
import SectionWithScrollBg from 'components/SectionWithScrollBg'

import projects_settings from 'site/projects/settings.json'

import { useStaticQuery, graphql } from 'gatsby'
import PortfolioItem from 'components/Portfolio/PortfolioItem'

const ProudOfSection = ({
  itemsToShow,
  filterCallback,
  className,
  subTitleClassName,
}) => {
  const [cropedPosts, setCropedPosts] = useState([])

  const { allMarkdownRemark } = useStaticQuery(
    graphql`
      query {
        allMarkdownRemark(
          filter: { fileAbsolutePath: { regex: "/(portfolio)/" } }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          nodes {
            fields {
              slug
            }
            frontmatter {
              title
              description
              pages_to_show
              preview_image {
                publicURL
              }
              tags
              industry
            }
          }
        }
      }
    `,
  )

  useEffect(() => {
    if (!allMarkdownRemark.nodes) {
      return console.log('no post')
    }
    const firstFourPosts = allMarkdownRemark.nodes
      .filter(filterCallback)
      .filter((item, i) => i < itemsToShow)
    setCropedPosts(firstFourPosts)
  }, [allMarkdownRemark])

  return (
    <SectionWithScrollBg className={className}>
      <div className="row">
        <div className="col-xxl-8 col-lg-8 col-md-12">
          <Title text={projects_settings?.title || ''} className="text-white" />
          <SubTitle
            text={projects_settings?.sub_title || ''}
            className={subTitleClassName}
          />
        </div>
      </div>

      <div className={styles.container}>
        {cropedPosts.map(({ frontmatter, fields: { slug } }) => (
          <PortfolioItem
            {...frontmatter}
            linkTo={`/portfolio${slug}`}
            titleClass="text-white text-center"
            subTitleClass="grey-text text-center"
            key={slug}
          />
        ))}
      </div>

      <div className={styles.linkContainer}>
        <DefaultLink to="/portfolio" title="See all works" variant="light" />
      </div>
    </SectionWithScrollBg>
  )
}
export default ProudOfSection
