import React from 'react'
import * as styles from './Post.module.scss'

import { Link } from 'gatsby'
import { Title } from 'ui'

const Post = ({ link, imageSrc, title, category, author }) => {
  return (
    <Link to={link} itemProp="url" className={styles.post}>
      <div className={styles.post__cover}>
        <img src={imageSrc} alt="post-image" />
      </div>
      <div className={styles.post__category}>
        {category || 'Processes and Tools'}
      </div>
      <Title variant="small" text={title} className={styles.post__title} />
      {/* <h2 className={styles.post__title} itemProp="headline">
        {title}
      </h2> */}
      {author && <p className={styles.post__author}>by {author}</p>}
    </Link>
  )
}

export default Post
