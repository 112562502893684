import React from 'react'
import * as styles from './BreadCrumb.module.scss'
import cn from 'classnames'

import { Link } from 'gatsby'

const BreadCrumb = ({ linkTo, linkTitle, title }) => (
  <div className="container pt-5">
    <div className="row">
      <div className="col-xxl-8 col-lg-8 col-md-12">
        <Link to={linkTo} className={styles.link}>
          <span className={cn(styles.text, styles.grey)}>{linkTitle} / </span>
        </Link>
        <b className={cn(styles.text, styles.defaultCursor)}>{title}</b>
      </div>
    </div>
  </div>
)

export default BreadCrumb
